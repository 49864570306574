/* your styles go here */

.header-logo {
  margin-top: 12px;
  margin-bottom: 12px;
}

a {
  color: #272370;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #dc6258;
  text-decoration: underline;
}

nav.navbar {
  background: #f5f3f5;
  padding: 0;
}

nav.side-navbar {
  color: #666666;
}

footer.main-footer {
  background: #f5f3f5;
}

nav.navbar .menu-btn span {
  background: #4a4a4a;
}

nav.navbar a {
  color: #4a4a4a;
}

nav.side-navbar ul li a:hover {
  background: #272370;
  border-left: 4px solid #dc6258;
}

.activeLink {
  background: #272370 !important;
  border-left: 4px solid #dc6258 !important;
  color: #fff !important;
}

nav.navbar .menu-btn span:nth-of-type(2)::before,
nav.navbar .menu-btn span:nth-of-type(2)::after {
  background: #4a4a4a;
}

footer.main-footer {
  color: #4a4a4a;
}

.login-page .form-holder .info {
  background: #f5f3f5;
}

.bg-orange {
  background: #dc6258 !important;
  color: #ffffff;
}

.btn-primary {
  color: #dc6258;
  background-color: #ffffff;
  border-color: #dc6258;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #dc6258;
  border-color: #ffffff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #dc6258;
  border-color: #ffffff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 98, 88, 0.5);
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 98, 88, 0.5);
  color: #fff;
  background-color: #dc6258;
  border-color: #fff;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dc6258;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(220, 98, 88, 0.5);
}

.srv-validation-message {
  color: red;
}

.pointer {
  cursor: pointer;
}

.css-yk16xz-control {
  border-color: #dc6258;
  border-radius: 0px !important;
}

.css-1pahdxg-control,
.css-1pahdxg-control:hover {
  border-color: #dc6258 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 98, 88, 0.5) !important;
  border-radius: 0px !important;
}

.page-content>.main-footer {
  position: fixed !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.fade.show {
  opacity: 1;
}

.modal {
  z-index: 1072;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999;
}

.simulations-section .simulations {
  width: 100%;
  padding-left: 0rem;
}

.simulations-section .simulations .simulation {
  margin-bottom: 2rem;
}

.header-title {
  margin-right: 5em;
  color: #272370;
  text-transform: uppercase;
}

.title-container {
  max-width: 150px;
}

.user-title {
  overflow: hidden;
  white-space: nowrap;
}

.inner-container {
  min-height: calc(100vh - 70px);
}

.color-gray {
  color: #666 !important;
}

/* Button submit */

.btn-submit {
  color: #272370;
  background-color: #ffffff;
  border-color: #272370;
  margin-left: 4px;
  margin-right: 4px;
  min-width: 140px;
}

.btn-submit:hover {
  color: #ffffff;
  background-color: #272370;
  border-color: #ffffff;
}

.btn-submit:not(:disabled):not(.disabled):active,
.btn-submit:not(:disabled):not(.disabled).active,
.show>.btn-submit.dropdown-toggle {
  color: #ffffff;
  background-color: #272370;
  border-color: #ffffff;
}

.btn-submit:not(:disabled):not(.disabled):active:focus,
.btn-submit:not(:disabled):not(.disabled).active:focus,
.show>.btn-submit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 35, 112, 0.5);
}

.btn-submit:focus,
.btn-submit.focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 35, 112, 0.5);
  color: #fff;
  background-color: #272370;
  border-color: #fff;
}

/* Button cancel */

.btn-cancel {
  color: #dc6258;
  background-color: #ffffff;
  border-color: #dc6258;
  min-width: 140px;
  margin-left: 4px;
  margin-right: 4px;
}

.btn-cancel:hover {
  color: #ffffff;
  background-color: #dc6258;
  border-color: #ffffff;
}

.btn-cancel:not(:disabled):not(.disabled):active,
.btn-cancel:not(:disabled):not(.disabled).active,
.show>.btn-cancel.dropdown-toggle {
  color: #ffffff;
  background-color: #dc6258;
  border-color: #ffffff;
}

.btn-cancel:not(:disabled):not(.disabled):active:focus,
.btn-cancel:not(:disabled):not(.disabled).active:focus,
.show>.btn-cancel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 98, 88, 0.5);
}

.btn-cancel:focus,
.btn-cancel.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 98, 88, 0.5);
  color: #fff;
  background-color: #dc6258;
  border-color: #fff;
}

.mail-preview-container {
  border: 1px black solid;
  border-radius: 8px;
  margin-top: 12px;
  padding: 16px;
  min-height: 120px;
}

.sim-link-btn {
  padding: 6px;
  margin-bottom: 8px;
  background: #272370;
  border-radius: 3px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

.simulation-video {
  padding-top: 0 !important;
}

.video-react {
  position: unset;
}

.video-react-control-bar {
  display: none !important;
}

.email-editor {
  z-index: 0;
}

.required:after {
  content: " *";
  color: red;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
  font-size: 12pt;
}

.custom-control {
  z-index: initial !important;
}

.custom-check-box-xl {
  -ms-transform: scale(1.8);
  /* IE */
  -moz-transform: scale(1.8);
  /* FF */
  -webkit-transform: scale(1.8);
  /* Safari and Chrome */
  -o-transform: scale(1.8);
  /* Opera */
  transform: scale(1.8);
}

.paragraph-in-table {
  white-space: pre-line;
  text-align: left;
}

.sim-detail-description {
  white-space: pre-line;
  text-align: left;
  font: 14 "Lucida Grande", Helvetica, Sans-Serif;
}

.error {
  color: #c82333;
}

.loading_message {
  background: #f5f5f5;
  padding: 12px;
  color: #c82333;
  font-size: 18px;
  font-weight: 700;
  margin-left: -75px;
}

.adjust-detail-height {
  height: 20vw !important;
}

.stats-table-header {
  background-color: #EEF5F9;
}

.dashboard-stats-text {
  color: #4A4A4A;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #C1C1C1;
  border-radius: 6px;
}

.csv-error-msg {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;
}

/* feedback */

.large-rating-icons {
  transform: scale(2.8);
}

.els-tool-tip {
  background: #f5f3f5;
  padding: 2px 10px 2px 10px;
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform .15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-weight: 900;
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}

.sidebar-menu a:hover {
  color: white !important;
}

.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}

img {
  color: black !important;
  text-align: unset !important;
  text-indent: unset !important;
}

.single_sim_playhead {
  color: #e4e3e3;
}

.error-msg{
  color: red !important;
  font-size: 1rem !important; 
  font-family: "Roboto-Regular !important";
}

.account-name-heading {
  font-weight: bold;
}

.account-name-value {
  font-weight: normal;
}

.accreditation-msg{
  text-align: center;
  font-weight: bold;
}

.increased-height-row {
  height: 45px;
}

.btn-styling{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.color-blue {
  color: #302d76;
}
